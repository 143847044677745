<template>
  <div class="variable"
       @click="click()"
       title="Click to copy variable"
  >
    {{ copied ? 'Copied' : `${variable.title} - ${variable.value}` }}
  </div>
</template>

<script>
export default {
  name: "AppVariable",
  props: {
    variable: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    copied: false,
  }),
  methods: {
    click() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.variable.value)
            .then(() => {
              new Promise((resolve) => {
                this.copied = true;
                setTimeout(resolve, 2500);
              }).then(() => {
                this.copied = false;
              });
            });
      } else {
        const tempInput = document.createElement('textarea');
        tempInput.value = this.variable.value;
        document.body.append(tempInput);
        this.copied = true;
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
        setTimeout(() => {
          this.copied = false;
        }, 2500);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixins.scss";

.variable {
  @include button;
  padding: 10px 15px;
  min-width: 170px;
}
</style>

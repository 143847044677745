<template>
<div class="quick-search">
  <label for="quick-search">
    Quick search
    <input type="text"
           name="quick-search"
           id="quick-search"
           autocomplete="off"
           v-model="search"
           :placeholder="placeholder"
           @keyup="onType"
           @paste="onType"
    >
  </label>
</div>
</template>

<script>
export default {
  name: "AppQuickSearch",
  props: {
    placeholder: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    search: '',
  }),
  methods: {
    onType() {
      this.$emit('search', this.search.toLowerCase());
    },
  }
}
</script>

<style scoped lang="scss">
@import '../styles/mixins';
@import '../styles/colors';

.quick-search {
  display: flex;
  margin-bottom: 20px;

  & input {
    @include input;
  }
}

</style>

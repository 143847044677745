import Filter from "@/modules/page-item/classes/filter";

const DefaultFilters = {
    videos: [
        new Filter('Recent videos', 'sort', '-created'),
        new Filter('Most viewed videos', 'sort', '-views'),
    ],
    models: [
        new Filter('Sort by name a -> z', 'sort', 'title'),
        new Filter('Most viewed', 'sort', '-views'),
        new Filter('Sort by number of videos', 'sort', '-videos_count'),
    ],
    channels: [
        new Filter('Recently updated channels', 'sort', '-updated'),
        new Filter('Sort by number of videos', 'sort', '-videos_count'),
    ],
    playlists: [
        new Filter('Newest playlists', 'sort', '-created'),
        new Filter('Sort by number of videos', 'sort', '-videos_count'),
    ]
}

export default DefaultFilters;

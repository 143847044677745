<template>
  <app-header v-if="isAuth"
              @logout="onLogout"
  />
<main>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
</main>
</template>

<script>
import UserService from "@/services/user-service";
import AppHeader from '@/components/Header';

import router from "@/router";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: 'App',
  components: {
    AppHeader,
  },
  beforeMount() {
    this.checkAuth();
  },
  computed: {
    ...mapGetters({
      isAuth: 'getAuthStatus',
      getCurrentTitle: 'getCurrentTitle'
    })
  },
  methods: {
    ...mapMutations(['setAuth', 'setCurrentUser']),
    async checkAuth() {
      this.setAuth(UserService.isAuth());

      if (this.isAuth) {
        const userInfo = await UserService.fetchUserInfo();

        if (!userInfo.error) {
          UserService.setUser(userInfo.data);
          this.setCurrentUser(userInfo.data);
        }

        if (userInfo.error) {
          UserService.logout();
          await router.push('/login');
        }
      } else {
        await router.push('/login');
      }
    },
    onLogout() {
      UserService.logout();
      router.push('/login');
      this.setAuth(UserService.isAuth());
    },
  },
}
</script>

<style lang="scss">
@import 'styles/colors';
@import 'styles/transitions';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $bgColor;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
}

@include list-back;
@include list-forward;
@include fade;

</style>

<template>
  <div class="editable-value">
    <slot/>
    <div class="editable-value__edit" v-if="isEditMode">
      <input type="text"
             v-model="editingValue"
             @keydown.enter="save"
      >
      <div class="editable-value__buttons">
        <div class="editable-value__button editable-value__button--icon"
             :class="{
                'editable-value__button--loading': isLoadingLoc
             }"
             @click="save"
        >
          <svg v-if="!isLoadingLoc">
            <use xlink:href="@/assets/icons.svg#save"></use>
          </svg>

          <span v-else class="editable-value__loader"></span>
        </div>
        <div class="editable-value__button editable-value__button--icon editable-value__button--red"
             @click="cancel"
             v-if="!isLoadingLoc && (saved || value)"
        >
          <svg>
            <use xlink:href="@/assets/icons.svg#cross"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="editable-value__value" v-else>
      <span>
        {{ editingValue }}
      </span>
      <span @click="edit">
        {{ '(edit)' }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppEditableValue",
  props: {
    value: {
      type: String || Number,
      default: '',
    },
  },
  data: () => ({
    editBtnPressed: false,
    editingValue: '',
    isEditMode: null,
    isLoadingLoc: false,
    saved: false,
  }),
  computed: {
    ...mapGetters(['getLoadingGlob']),
  },
  mounted() {
    this.editingValue = this.value;
    this.isEditMode = !this.saved && !this.editingValue;
  },
  methods: {
    cancel() {
      this.isEditMode = false;
      this.editingValue = this.value;
    },
    edit() {
      this.isEditMode = true;
    },
    save() {
      if (!this.editingValue.length || this.isLoadingLoc || this.getLoadingGlob) return;

      this.$emit('confirm', this.editingValue);
      this.isLoadingLoc = true;

      let watcher;
      const sub_watch = () => {
        if (this.getLoadingGlob) {
          watcher = setTimeout(sub_watch, 200);
        } else {
          clearTimeout(watcher);
          this.saved = true;
          this.isEditMode = false;
          this.isLoadingLoc = this.getLoadingGlob;
        }
      }

      sub_watch();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";
@import "../styles/mixins";

.editable-value {
  display: flex;
  align-items: center;
  min-height: 50px;
  margin-bottom: 10px;
}

.editable-value__edit {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.editable-value__buttons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.editable-value__button {
  @include button;
}

.editable-value__edit input {
  @include input;
  width: 300px;
}

.editable-value__value {
  margin-left: 10px;

  & > span:first-child {
    font-weight: bold;
    white-space: nowrap;
  }

  & > span:last-child {
    margin-left: 5px;
    font-size: .8rem;
    opacity: .6;
    cursor: pointer;
    @include transition;
    text-decoration: underline;

    &:hover {
      opacity: .8;
    }
  }
}

.editable-value__loader {
  @include loader--primary;
}

</style>

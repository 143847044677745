export default class Meta {
    constructor(title = '', description = '', keywords = '', guid = '') {
        const langs = ['ar', 'ch', 'en', 'de', 'fr', 'es', 'in', 'it', 'jp', 'ko', 'ru'];

        langs.forEach(lang => {
           this[lang] = {
               title,
               description,
               keywords,
               guid,
           }
        });
    }
}

import Variable from "@/modules/page-item/classes/variable";
import Filter from "@/modules/page-item/classes/filter";

export default class PageStruct {
    constructor() {
        this.defaultFilterMeta = {
            about: '',
        };
        this.customFiltersMeta = [];
        this.pagination = false;
        this.hidden = false;
        this.variables = [];
        this.guid = '';
    }

    addVariable(method, title, value) {
        this.variables = this.removeEmpty(this.variables);

        const hasDuplicate = this.variables.find(variable => {
            return (variable.method === method)
                && (variable.title === title)
                && (variable.value === value) ;
        });

        if (!hasDuplicate) {
            this.variables.push(new Variable(method, title, value));
        }
    }

    addFilter(about, param, value) {
        this.customFiltersMeta = this.removeEmpty(this.customFiltersMeta);

        const hasDuplicate = this.customFiltersMeta.find(filter => {
           return (filter.about === about)
               && (filter.param === param)
               && (filter.value === value) ;
        });

        if (!hasDuplicate) {
            this.customFiltersMeta.push(new Filter(about, param, value));
        }
    }

    removeVariable(index) {
        this.variables = this.variables.filter((variable, i) => {
            return index !== i;
        });
    }

    removeFilter(index) {
        this.customFiltersMeta = this.customFiltersMeta.filter((filter, i) => {
            return index !== i;
        });
    }

    setStruct(page) {
        this.guid = page.guid;
        this.pagination = page.pagination;
        this.variables = page.variables;
        this.customFiltersMeta = page.customFiltersMeta;
        this.defaultFilterMeta = page.defaultFilterMeta;
        this.hidden = page.hidden;
    }

    setDefaultFilter(newObj) {
        this.defaultFilterMeta = newObj;
    }

    removeEmpty(array) {
        return array.filter(item => {
            return [...Object.values(item)].some(value => value);
        });
    }

    unique(array) {
        return array.filter((item, index, self) => {
            return self.findIndex(_item => {
                return Object.keys(item).every(key => {
                    if (key === 'guid') return true;
                    return item[key] === _item[key];
                });
            }) === index;
        });
    }

    getStruct() {
        const struct = {
            defaultFilterMeta: this.defaultFilterMeta,
            customFiltersMeta: this.unique(this.customFiltersMeta),
            pagination: this.pagination,
            variables: this.unique(this.variables),
            hidden: this.hidden,
        }

        if (this.guid) {
            struct.guid = this.guid;
        }

        return struct;
    }
}

<template>
<div class="variables" v-if="_variables && _variables.length">
  <h3 class="variables__title">
    Available variables:
  </h3>
  <div class="variables__list">
    <app-variable v-for="(variable, index) in _variables"
                  :variable="variable"
                  :key="index + variable.value"
    />
  </div>
</div>
</template>

<script>
import AppVariable from "@/modules/page-item/components/Variable.vue";
export default {
  name: "AppVariables",
  components: {
    AppVariable,
  },
  props: {
    variables: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    copied: false,
  }),
  computed: {
    _variables() {
      return this.variables && this.variables.filter(variable => {
          return [...Object.values(variable)].every(value => value);
      });
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.variables {
  display: flex;
  margin-top: 10px;
}

.variables__list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 10px;
}
</style>

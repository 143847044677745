import APIService from "@/services/api-factory";

export default class UserService {
    token = null;
    user = null;

    static fetchUserInfo = APIService.get('/auth/self');

    static getToken() {
        return this.token || localStorage.getItem('token');
    }

    static getUserInfo() {
        return this.user;
    }

    static isAuth() {
        return !!this.getToken();
    }

    static login = APIService.post('/auth/login');

    static logout() {
        localStorage.removeItem('token');
        this.token = null;
    }

    static setToken(token) {
        localStorage.setItem('token', token);
        this.token = token;
    }

    static setUser(user) {
        this.user = user;
    }
}

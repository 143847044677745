<template>
<div class="page-output">
  <div class="page-output__section">
    <div class="page-output__top">
      <h3 class="page-output__title">
        Title
      </h3>
      <div class="page-output__button"
           @click="copy('title', 'copiedTitle')"
      >
        {{ copiedTitle ? 'Copied' : 'Copy' }}
      </div>
      <label class="page-output__checkbox">
        <input type="checkbox" v-model="minimizeTitle">
        <span>Minimize code</span>
      </label>
    </div>
    <div class="page-output__code">
      {{ outputTitle }}
    </div>
  </div>

  <div class="page-output__section">
    <div class="page-output__top">
      <h3 class="page-output__title">
        Description
      </h3>
      <div class="page-output__button"
           @click="copy('description', 'copiedDescription')"
      >
        {{ copiedDescription ? 'Copied' : 'Copy' }}
      </div>
      <label class="page-output__checkbox">
        <input type="checkbox" v-model="minimizeDescription">
        <span>Minimize code</span>
      </label>
    </div>
    <div class="page-output__code">
      {{ outputDescription }}
    </div>
  </div>

  <div class="page-output__section">
    <div class="page-output__top">
      <h3 class="page-output__title">
        Keywords
      </h3>
      <div class="page-output__button"
           @click="copy('keywords', 'copiedKeywords')"
      >
        {{ copiedKeywords ? 'Copied' : 'Copy' }}
      </div>
      <label class="page-output__checkbox">
        <input type="checkbox" v-model="minimizeKeywords">
        <span>Minimize code</span>
      </label>
    </div>
    <div class="page-output__code">
      {{ outputKeywords }}
    </div>
  </div>
</div>
</template>

<script>
import OutputGenerator from '@/services/output-generator';

import { mapGetters } from "vuex";

export default {
  name: "AppPageOutput",
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    outputTitle: '',
    outputDescription: '',
    outputKeywords: '',
    copiedTitle: false,
    copiedDescription: false,
    copiedKeywords: false,
    minimizeTitle: true,
    minimizeDescription: true,
    minimizeKeywords: true,
  }),
  computed: {
    ...mapGetters(['getLanguage']),
  },
  created() {
    this.outputGenerator = new OutputGenerator(this.page, this.getLanguage);
  },
  mounted() {
    this.outputTitle = this.outputGenerator.generate('title');
    this.outputDescription = this.outputGenerator.generate('description');
    this.outputKeywords = this.outputGenerator.generate('keywords');
  },
  methods: {
    copy(meta, variable) {
      let text, shouldMinimize;
      switch (meta) {
        case 'title':
          text = this.outputTitle;
          shouldMinimize = this.minimizeTitle;
          break;
        case 'description':
          text = this.outputDescription;
          shouldMinimize = this.minimizeDescription;
          break;
        case 'keywords':
          text = this.outputKeywords;
          shouldMinimize = this.minimizeKeywords;
          break;
      }

      if (shouldMinimize) {
        text = text.replace(/[\n\t]/g, '')
            .replace(/{{ /g, '{{')
            .replace(/ }}/g, '}}')
            .replace(/}} {{/g, '}}{{');
      }

      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => {
              new Promise((resolve) => {
                this[variable] = true;
                setTimeout(resolve, 2500);
              }).then(() => {
                this[variable] = false;
              });
            });
      } else {
        const tempInput = document.createElement('textarea');
        tempInput.value = text;
        tempInput.style.display = 'none';
        document.body.append(tempInput);
        this[variable] = true;
        tempInput.focus();
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
        setTimeout(() => {
          this[variable] = false;
        }, 2500);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.page-output__top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.page-output__button {
  @include button;
  padding: 10px 15px;
  min-width: 150px;
}

.page-output__checkbox {
  margin-left: 20px;
  @include checkbox;
}

.page-output__title {
  margin: 0 20px 0 0;
}

.page-output__code {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>

<template>
  <header class="header">
    <h1 class="header__title">
      {{ title }}
    </h1>

    <AppBackButton />

    <div class="header__button"
         @click="logout"
    >
      Logout
    </div>
  </header>
</template>

<script>
import AppBackButton from '@/components/BackButton';
import { mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    AppBackButton,
  },
  computed: {
    ...mapGetters({
      title: "getCurrentTitle",
    }),
  },
  methods: {
    logout() {
      this.$emit('logout');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 70px;
  width: 100%;
  margin: 0 auto;
  color: #eee;
  font-weight: bold;

  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 70px;
    background-color: $themeColor;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}

.header__title {
  margin: 0;
  font-size: 25px;
  user-select: none;
}

.header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: rgba(#fff, .2);
  cursor: pointer;
  transition: .3s ease-out;
  user-select: none;
}

.header__button:hover {
  background-color: rgba(#fff, .35);
}
</style>

import TEMPLATES from './templates/templates';
import PageTranslations from "@/services/translations/page-translations";

class OutputGenerator {
    constructor(page, language = 'en') {
        this.page = page;
        this.language = language;
        this.pageWord = PageTranslations[language] || 'page';
        this.output = '';
        this.hasDefaultMeta = page.defaultFilterMeta.meta[this.language].translated;
        this.hasCustomFilters = page.customFiltersMeta && !!page.customFiltersMeta.length;
        this.customFiltersParams = page.customFiltersMeta && [...new Set(page.customFiltersMeta.map(item => item.param))];
        this.hasVariables = page.variables && !!page.variables.length;
        this.variables = page.variables && page.variables
            .filter(item => item.value && item.method && item.title)
            .map(item => item.value);
    }

    addBlock(block) {
        this.output += block + '\n';
    }

    serveVariables(text) {
        return text.replace(/(\$(\w+))/g, '{{ $1 }}')
    }

    generate(meta) {
        const allowPagination = meta !== 'keywords';
        const allowVariables = meta !== 'keywords';

        this.output = '';

        // Adding page variables
        if (this.page.pagination && allowPagination) {
            this.addBlock(TEMPLATES.defines.paginationVariable);
        }

        // Adding filters variables
        if (this.hasCustomFilters && this.customFiltersParams.length) {
            this.customFiltersParams.forEach(item => {
                this.addBlock(TEMPLATES.defines.customFilterByName(item));
            });
        }

        // Adding text variables
        if (this.hasVariables && allowVariables) {
            this.page.variables.forEach(variable => {
                const block = TEMPLATES.defines.customVariable(variable);
                this.addBlock(block);
            });
        }

        // Adding content variable
        if (this.hasDefaultMeta) {
            const text = this.page.defaultFilterMeta.meta[this.language][meta];
            const block = TEMPLATES.defines[meta](text);
            this.addBlock(block);
        }

        // Adding special titles for any sort
        if (this.hasCustomFilters) {
            this.page.customFiltersMeta.forEach(item => {
                const text = item.meta[this.language][meta];
                const content = TEMPLATES.assigns[meta](text);
                const block = TEMPLATES.blocks.filterBlock(item, content);
                this.addBlock(block);
            });
        }

        // Adding pagination postfix
        if (this.page.pagination && allowPagination) {
            const paginationBlock = TEMPLATES.blocks.paginationWrapper(meta, this.pageWord);
            this.addBlock(paginationBlock);
        }

        // Replacing variables
        if (this.hasVariables && allowVariables) {
            this.variables.forEach(variable => {
                const block = TEMPLATES.blocks.replacingVarsBlock(meta, variable);
                this.addBlock(block);
            });
        }

        // Adding result
        const result = TEMPLATES.blocks.printVariable([meta]);
        this.addBlock(result);

        return this.output;
    }
}

export default OutputGenerator;

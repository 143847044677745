const TEMPLATES = {
    defines: {
        paginationVariable: `{{ $p := 1 }}\n{{ if hasKey .__request.Query "p" }}\n\t{{ $p = .__request.Query.p | int }}\n{{ end }}\n`,
        sortVariable: `{{ $sort := "" }}\n{{ if hasKey .__request.Query "sort" }}\n\t{{ $sort = .__request.Query.sort | lower }}\n{{ end }}\n`,
        customFilter(filter) {
          return `{{ $${filter.param} := false }}\n{{ if hasKey .__request.Query "${filter.param}" }}\n\t{{ $${filter.param} = .__request.Query.${filter.param} | lower }}\n{{ end }}\n`;
        },
        customFilterByName(filter) {
            return `{{ $${filter} := "" }}\n{{ if hasKey .__request.Query "${filter}" }}\n\t{{ $${filter} = .__request.Query.${filter} | lower }}\n{{ end }} \n`;
        },
        customVariable(variable) {
            return `{{ ${variable.value} := ${variable.method} }}\n`
        },
        title(text) {
            return `{{ $title := "${text}" }}\n`
        },
        description(text) {
            return `{{ $description := "${text}" }}\n`
        },
        keywords(text) {
            return `{{ $keywords := "${text}" }}\n`
        },
    },
    assigns: {
      title(text) {
          return `{{ $title = "${text}" }}`;
      },
      description(text) {
          return `{{ $description = "${text}" }}`;
      },
      keywords(text) {
            return `{{ $keywords = "${text}" }}`;
      },
      customVariable(variable, text) {
          return `{{ ${variable.value} = "${text}" }}`;
      }
    },
    blocks: {
        resultTitle: `{{ $title }}\n`,
        resultDescription: `{{ $description }}`,
        resultKeywords: `{{ $keywords }}`,
        printVariable(variable) {
            return `{{ $${variable} }}`
        },
        filterBlock(filter, content) {
            return `{{ if eq $${filter.param} "${filter.value}" }}\n\t${content}\n{{ end }}\n`;
        },
        filterBlockSort(value, content) {
            return `{{ if eq "sort" "${value}" }}\n\t${content}\n{{ end }}`;
        },
        paginationWrapper(meta, translation) {
            return `{{ if gt $p 1 }}\n\t{{ $${meta} = cat $${meta} "|" ("${translation}" | title) $p }}\n{{ end }}\n`
        },
        replacingVarsBlock(meta, variable) {
            return `{{ $${meta} = regexReplaceAll "${variable}" "$${meta}" ${variable} }}\n`;
        }
    }
};

export default TEMPLATES;

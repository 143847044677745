<template>
<div class="site-overview" v-if="site">
  <div class="site-overview__controls">
    <AppSiteLanguages :languages="site.languages"
    />
    <AppNicheSwitch :current-niche="filterNiche"
                    :niches="site.niches"
                    @select="setNiche"
    />
  </div>


  <AppQuickSearch @search="onSearch"
                  placeholder="Page title or url..."
  />

  <AppPageItem v-for="(page, index) in filteredPages"
      :page="page"
      :key="index"
      :siteGuid="site.guid"
      @page-updated="getSiteInfo"
  />
</div>
<app-loader v-else></app-loader>
</template>

<script>
import AppPageItem from '@/modules/page-item/PageItem';
import AppSiteLanguages from '@/modules/site-overview/components/SiteLanguages';
import AppQuickSearch from  '@/components/QuickSearch.vue';
import AppLoader from "@/components/Loader.vue";
import AppNicheSwitch from '@/modules/site-overview/components/NicheSwitch.vue'

import SitesService from "@/services/sites-service";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppSiteOverview",
  components: {
    AppLoader,
    AppPageItem,
    AppSiteLanguages,
    AppQuickSearch,
    AppNicheSwitch,
  },
  data: () => ({
    currentLanguage: '',
    error: null,
    isLoading: false,
    site: null,
    filterName: '',
    filterNiche: '',
  }),
  computed: {
    ...mapGetters(['getLanguage', 'getCurrentTitle', 'isAdmin']),
    filteredPages() {
      return this.site.pages
              .filter(page => {
                return this.isAdmin || !page.hidden
              })
              .filter(page => {
                return !this.filterName || (page.title.toLowerCase().includes(this.filterName)
                  || page.url.toLowerCase().includes(this.filterName))
              })
              .filter(page => {
                return this.site.niches.length === 1 || page.niche === this.filterNiche;
              })
          || [];
    },
  },
  methods: {
    ...mapMutations(['setCurrentTitle', 'setLanguage']),
    async getSiteInfo() {
      this.isLoading = true;
      const getSiteRequest = await SitesService.getByGuid(this.guid)

      if (getSiteRequest.success) {
        this.site = getSiteRequest.data;
        this.filterNiche = this.getDefaultNiche();
      }

      if (getSiteRequest.error) {
        this.error = getSiteRequest.error;
      }

      this.isLoading = false;
    },
    getDefaultNiche() {
      return this.site.niches.length === 1 ? 'straight' : this.site.niches[0];
    },
    onSearch(text) {
      this.filterName = text;
    },
    setNiche(niche) {
      this.filterNiche = niche;
    }
  },
  created() {
    this.guid = this.$route.params.guid;
    this.currentLanguage = this.$route.params.language || 'en';

  },
  async beforeMount() {
    await this.getSiteInfo();
    this.setCurrentTitle(this.site.title);

    document.title = this.getCurrentTitle;
  },
  watch: {
    $route() {
      this.setLanguage(this.$route.params.language || 'en');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/colors";
@import "../../styles/mixins";

.site-overview {
  @include content-container;
}

.site-overview__controls {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div class="login">
    <form class="login__form"
          id="login-form"
          @submit.prevent="auth"
    >
        <h1 class="login__header">
          Sign in
        </h1>

        <span class="login__error"
              v-if="error"
        >
          {{ error }}
        </span>

        <!--         Login         -->
        <div class="login__screen"
             v-if="currenScreen === 'login'"
        >
          <label class="login__field"
                 :class="{
                  'login__field--has-error': error,
                 }"
          >
            <input type="text"
                   name="login"
                   id="login"
                   v-model="login"
                   placeholder="Login"
                   @keyup.enter.prevent="setScreen('password')"
                   autofocus
            >
          </label>

          <div class="login__button"
               :class="{
                  'login__button--disabled': !login.length,
                 }"
               @click="setScreen('password')"
          >
            <svg>
              <use :xlink:href="`${require('@/assets/icons.svg')}#arrow-right`"></use>
            </svg>
          </div>
        </div>

        <!--         Password         -->
        <div class="login__screen"
             v-if="currenScreen === 'password'"
        >
          <label class="login__field"
                 :class="{
                  'login__field--has-error': error,
               }"
          >
            <input :type="shouldPasswordShow ? 'text' : 'password'"
                   name="password"
                   id="password"
                   v-model="password"
                   placeholder="Password"
                   autocomplete="off"
                   autofocus
            >

            <span class="login__show-password"
                  @mouseenter="showPassword"
                  @mouseleave="hidePassword"
            >
              <svg width="12" height="12">
                <use :xlink:href="`${require('@/assets/icons.svg')}#views`"></use>
              </svg>
            </span>
          </label>

          <button class="login__button"
                  :class="{
                    'login__button--disabled': !login.length || !password.length,
                 }"          >
            <svg>
              <use :xlink:href="`${require('@/assets/icons.svg')}#arrow-right`"></use>
            </svg>
          </button>
        </div>

      <!--         Loader         -->
      <app-loader v-if="currenScreen === 'loader'"></app-loader>
    </form>
  </div>
</template>

<script>
import UserService from "@/services/user-service";
import router from "@/router";
import AppLoader from "@/components/Loader.vue";
import { mapMutations } from "vuex";

export default {
  name: "AppLogin",
  components: {
    AppLoader
  },
  data: () => ({
    currenScreen: 'login',
    error: '',
    login: '',
    password: '',
    shouldPasswordShow: false,
  }),
  beforeMount() {
    if (UserService.isAuth()) {
      router.push('/');
    }
  },
  methods: {
    ...mapMutations(['setAuth', 'setCurrentUser']),
    async auth() {
      if (!this.login || !this.password) return;

      const data = {
        login: this.login,
        password: this.password
      }

      this.setScreen('loader');

      const auth = await UserService.login(data);

      if (auth.error) {
        this.resetForm();
        this.error = auth.error;
        return;
      }

      const { token, ...user } = auth.data;

      UserService.setUser(user);
      UserService.setToken(token);

      this.setAuth(UserService.isAuth());
      this.setCurrentUser(UserService.getUserInfo());
      await router.push('/');
    },
    hidePassword() {
      this.shouldPasswordShow = false;
    },
    resetForm() {
      this.login = '';
      this.password = '';
      this.setScreen();
    },
    setScreen(name = 'login') {
      this.currenScreen = name;
      this.$nextTick(() => {
        document.querySelector(`#${name}`)?.focus();
      });
    },
    showPassword() {
      this.shouldPasswordShow = true;
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../styles/colors";
  @import "../styles/mixins";

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
  }

  .login__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 380px;
    min-height: 160px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 15px;
  }

  .login__header {
    margin: 0;
  }

  .login__screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
  }

  .login__field {
    display: flex;
    width: 100%;
    position: relative;
    gap: 15px;
  }

  .login__field input {
    @include input;

    flex-grow: 1;
    width: 100%;
    height: 40px;
  }

  .login__field--has-error input {
    border-color: crimson;
  }

  .login__field--has-error input:hover {
    box-shadow: 0 0 5px 2px rgba(crimson, .3);
    border-color: rgba(crimson, .3);
  }

  .login__error {
    color: crimson;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .login__show-password {
    position: absolute;
    right: 15px;
    top: 50%;
    translate: 0 -50%;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      left: 50%;
      top: 50%;
      translate: -50% -50%;
      position: absolute;
    }
  }

  .login__button {
    @include button;

    padding: 10px;
    height: 40px;
    min-width: 40px;
    border: 0;

    & > svg {
      width: 15px;
      height: 15px;
    }
  }

  .login__button--disabled {
    pointer-events: none;
    background-color: #aaa;
  }
</style>

<template>
  <div class="niche-switch"
       v-if="niches.length > 1"
  >
    <h2 class="niche-switch__title">
      Niche
    </h2>
    <div class="niche-switch__list">
      <div v-for="niche in niches"
           class="niche-switch__item"
           :class="{
          'niche-switch__item--current': isCurrentNiche(niche)
         }"
           :key="niche"
           :title="niche"
           @click="click(niche)"
      >
        <svg>
          <use :xlink:href="`${require('@/assets/icons.svg')}#${niche}`"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNicheSwitch",
  props: {
    niches: {
      type: Array,
      required: true,
    },
    currentNiche: {
      type: String,
      required: true,
    },
  },
  computed: {

  },
  methods: {
    click(niche) {
      this.$emit('select', niche);
    },
    isCurrentNiche(niche) {
      return niche === this.currentNiche;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.niche-switch__title {
  margin: 0 0 10px 0;
}

.niche-switch__list {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #aaa;
}

.niche-switch__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  cursor: pointer;

  @include transition;
}

.niche-switch__item:hover {
  background-color: rgba($themeColor, .1);
}

.niche-switch__item svg {
  width: 25px;
  height: 25px;
}

.niche-switch__item--current {
  pointer-events: none;
  background-color: $themeColor;

  & > svg {
    fill: #fff;
  }
}
</style>

<template>
  <div class="page-item"
       ref="item"
       :class="{
              'page-item--current': isExpanded,
           }"
  >
    <div class="page-item__top"
    >
      <app-page-item-status :page="page"
      />
      <router-link :to="routerLink" class="page-item__expand"
                   :class="{
                      'page-item__expand--expanded': isExpanded,
                   }"
                   @click="switchExpand"
      >
        <svg>
          <use xlink:href="@/assets/icons.svg#arrow"></use>
        </svg>
      </router-link>
      <router-link :to="routerLink"
                   class="page-item__title"
                   @click="switchExpand"
      >
        {{ page.title }}
      </router-link>
      <span class="page-item__url">
        {{ page.url }}
      </span>
    </div>

    <div class="page-item__bottom" v-if="isExpanded">
      <div class="page-item__controls" v-if="isAdmin">
        <div class="page-item__control"
             :class="{
                'page-item__control--inverted': isCurrentMode('translations'),
             }"
             @click="setMode('translations')"
        >
          Add texts
        </div>
        <div class="page-item__control"
             :class="{
                'page-item__control--inverted': isCurrentMode('settings'),
                'page-item__control--disabled': isCurrentMode('settings'),
             }"
             @click="setMode('settings')"
        >
          Page settings
        </div>
        <div class="page-item__control"
             :class="{
                'page-item__control--inverted': isCurrentMode('output'),
             }"
             @click="setMode('output')"
        >
          Output
        </div>
      </div>

      <AppPageItemForm :page="this.page"
                       v-if="isCurrentMode('translations')"
                       @save="saveTranslation"
      />
      <AppPageItemFilterEdit :page="this.page"
                             v-if="isCurrentMode('settings')"
                             @save-struct="saveStruct"
      />
      <AppPageOutput :page="page"
                     v-if="isCurrentMode('output')"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import AppPageItemForm from '@/modules/page-item/components/PageItemForm';
import AppPageItemFilterEdit from '@/modules/page-item/components/PageItemFilterEdit';
import AppPageItemStatus from '@/modules/page-item/components/PageItemStatus';
import AppPageOutput from '@/modules/page-item/components/PageOutput';

import PagesService from "@/services/pages-service";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppPageItem",
  components: {
    AppPageItemForm,
    AppPageItemFilterEdit,
    AppPageItemStatus,
    AppPageOutput,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    siteGuid: {
      type: String,
    }
  },
  created() {
    if (this.$route.params.pageGuid === this.page.guid) {
      this.switchExpand();
    }
    this.setLanguage(this.$route.params.language || 'en');
  },
  computed: {
    ...mapGetters(['getUserInfo', 'getLanguage', 'getLoadingGlob', 'isAdmin']),
    routerLink() {
      return this.isExpanded ? `/site/${this.siteGuid}/${this.getLanguage}`
          : `/site/${this.siteGuid}/${this.getLanguage}/${this.page.guid}`;
    },
  },
  data: () => ({
    isExpanded: false,
    currentMode: 'translations'
  }),
  methods: {
    ...mapMutations(['setLanguage', 'setLoadingGlob', 'setRequestStatus']),
    async saveStruct(struct) {
      this.setLoadingGlob(true);

      const setStruct = await PagesService.setStruct(this.page.guid)(struct)
          .finally(() => {
            this.setLoadingGlob(false);
          });

      if (setStruct.success) {
        this.$emit('page-updated');
      }
    },
    async saveTranslation(struct) {
      this.setLoadingGlob(true);

      const setTranslation = await PagesService.setTranslation(this.page.guid, this.getLanguage)(struct)
          .finally(() => {
            this.setLoadingGlob(false);
          });

      if (setTranslation.success) {
        this.$emit('page-updated');
        this.setRequestStatus('success');
      }

      this.setRequestStatus(setTranslation.error ? 'failure' : 'success');
    },
    setMode(mode) {
      this.currentMode = mode;
    },
    switchExpand() {
      this.isExpanded = !this.isExpanded;
      this.$emit('pageExpand', this.index);
      store.commit('setCurrentPage',this.isExpanded ? this.page.title : '');
    },
    isCurrentMode(mode) {
      return this.currentMode === mode;
    },
    minimize() {
      this.isExpanded = false;
    },
  },
  watch: {
    $route(to, from) {
      const isCurrentPage = this.$route.params.pageGuid === this.page.guid;
      this.setLanguage(this.$route.params.language || 'en');
      if (to.params.pageGuid && from.params.pageGuid && !isCurrentPage) this.minimize();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/colors";
@import "../../styles/mixins";

.page-item {
  @include list-item;
  flex-direction: column;
  align-items: flex-start;
}

.page-item__top {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.page-item__expand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 30px;
  height: 30px;
  background-color: rgba(#000, .05);
  margin-right: 10px;
  border-radius: 50%;
  @include transition;
  cursor: pointer;

  &--expanded {
    transform: rotate(180deg);
  }

  & > svg {
    width: 15px;
    height: 15px;
    fill: $themeColor;
  }
}

.page-item__title {
  @include list-item__title;
}

.page-item__url {
  font-size: 12px;
  margin-left: 15px;
  opacity: .6;
}

.page-item__bottom {
  width: 100%;
  padding-left: 40px;
  margin-top: 15px;
}

.page-item__controls {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.page-item__control {
  @include button;
}
</style>

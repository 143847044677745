<template>
  <div class="site-item">
    <router-link :to="`/site/${site.GUID}/en`"
                 class="site-item__open-button">
      <svg width="20" height="20">
        <use xlink:href="@/assets/icons.svg#edit"></use>
      </svg>
    </router-link>
    <router-link :to="`/site/${site.GUID}/en`"
                  class="site-item__title"
    >
      {{ site.title }}
    </router-link>
    <div class="site-item__languages" v-if="site.languages">
      <router-link class="site-item__language"
                   :to="`/site/${site.GUID}/${langName}`"
                   v-for="(language, langName) in site.languages"
                   :key="langName"
                   :title="langName"
                   :class="{
                      'site-item__language--active': language.translated
                   }"
      >
        <svg>
          <use :xlink:href="`${require('@/assets/icons.svg')}#${langName}`"></use>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSiteItem",
  props: {
    site: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.site-item {
  @include list-item;
}

.site-item__open-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: $themeColor;
  margin-right: 15px;
  cursor: pointer;

  & > svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    opacity: .7;
  }
}

.site-item__title {
  @include list-item__title;
}

.site-item__languages {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.site-item__language {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(#000, .3);

  & + & {
    margin-left: 10px;
  }

  & svg {
    width: 25px;
    height: 15px;
  }

  &:not(&--active) img {
    opacity: .5;
  }

  &--active {
    background-color: $themeColor;
    box-shadow: 0 0 5px 2px rgba($themeColor, .3);
  }
}

</style>

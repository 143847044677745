<template>
  <label class="custom-input"
         :class="{
            'custom-input--half': half,
         }"
  >
    <span class="custom-input__title">
          {{ title }}
    </span>
    <textarea @input="$emit('update:modelValue', $event.target.value)"
              :value="text"
              :rows="rows"
              @scroll="onScroll"
    />

    <span class="custom-input__mask"
          ref="mask"
          v-html="highlight(text)"
    />
    <span class="custom-input__counter"
          v-if="getLength()"
    >
      {{ getLength() }}
    </span>
  </label>
</template>

<script>
export default {
  name: "AppInputHighlighted",
  emits: ['update:modelValue'],
  props: {
    half: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    getLength() {
      return this.text.replace(/[{}]/g, '').length || 0;
    },
    highlight(text) {
      return text.replace(/(\$\w+)/g, '<strong style="color: #6907fd; font-weight: normal">$1</strong>')
    },
    onScroll(event) {
      this.$refs.mask.scrollTop = event.currentTarget.scrollTop || 0;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.custom-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & textarea {
    @include input;
    background-color: transparent;
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    font-size: 14px;
    height: unset;
    resize: none;
    font-family: sans-serif;
    color: transparent;
    caret-color: #6907fd;
    position: relative;
    z-index: 1;
  }

  &--half {
    width: calc(50% - 10px);
  }
}

.custom-input__title {
  display: inline-block;
  height: 18px;
}

.custom-input__mask {
  display: inline-block;
  position: absolute;
  bottom: 2px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  height: calc(100% - 25px);
  font-family: sans-serif;
  overflow: hidden;
  pointer-events: none;
  background-color: #fff;
  z-index: 0;
}

.custom-input__counter {
  position: absolute;
  right: 9px;
  top: 28px;
  font-size: 11px;
  color: #777;
}

</style>

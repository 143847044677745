<template>
<router-link :to="'/'"
             class="back-button"
             v-if="back"
>
  Sites List
</router-link>
</template>

<script>
export default {
  name: "AppBackButton",
  computed: {
    back() {
      return this.$route.path !== '/' && this.$router.options.history.state.back;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/colors";

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: rgba(#fff, .2);
  cursor: pointer;
  transition: .3s ease-out;
  user-select: none;
  margin-left: auto;
  margin-right: 15px;

  &:any-link {
    color: $themeTextColor;
    text-decoration: none;
  }
}

.back-button:hover {
  background-color: rgba(#fff, .35);
}
</style>

<template>
<div class="sites-filter"
     @click="set"
     v-if="translatedSitesCount"
>
  {{ isActive ? 'Show all' : `Hide translated (${translatedSitesCount})` }}
</div>
</template>

<script>
export default {
  name: "AppSitesFilter",
  props: {
    translatedSitesCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isActive: false,
  }),
  methods: {
    set() {
      this.isActive = !this.isActive;
      this.$emit('filter', this.isActive);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styles/mixins';
@import '../../../styles/colors';

.sites-filter {
  display: flex;
  align-items: center;
  justify-content:  center;
  padding: 15px 25px;
  background-color: $themeColor;
  color: $themeTextColor;
  font-weight: bold;
  border-radius: 10px;
  width: min-content;
  user-select: none;
  cursor: pointer;
  border: 1px solid $themeColor;
  @include transition;
  white-space: nowrap;

  &:hover {
    opacity: .7;
  }
}

</style>

import Variable from "@/modules/page-item/classes/variable";

const DefaultVariables = () => [
    new Variable('.Categories_GetCategoryByName.Title', 'Category name', '$category'),
    new Variable('.__request.Query.tagName', 'Tag', '$tag'),
    new Variable('.__request.Path.query', 'Search query', '$search'),
    new Variable('.__request.Path.letter', 'Letter', '$letter'),
    new Variable('.__request.Path.modelsQuery', 'Models search query', '$modelsSearch'),
    new Variable('.Models_GetModelInfoByName.Title', 'Model name', '$modelName'),
    new Variable('.Channels_GetChannelByName.Name', 'Channel title', '$channel'),
    new Variable('.Playlists_GetPlaylistByID.Name', 'Playlist title', '$playlist'),
    new Variable('.Videos_GetVideoByID.Title', 'Video title', '$videoTitle'),
];

export default DefaultVariables;

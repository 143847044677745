import Meta from "@/modules/page-item/classes/meta";

export default class TranslationStruct {
    constructor(page) {
        this.defaultFilterMeta = page.defaultFilterMeta.meta || new Meta();
        this.defaultFilterMeta.guid = page.defaultFilterMeta.guid;
        this.customFiltersMeta = page.customFiltersMeta.map(filter => {
           return { ...filter.meta, guid: filter.guid };
        });
    }

    getStruct(language) {
        delete this.defaultFilterMeta[language].translated;
        return ({
           defaultFilterMeta: {
               ...this.defaultFilterMeta[language],
               guid: this.defaultFilterMeta.guid,
           },
           customFiltersMeta: this.customFiltersMeta.map(filter => {
                delete filter[language].translated;
                return { ...filter[language], guid: filter.guid };
           }),
        });
    }
}

import AppSitesList from '@/modules/sites-list/SitesList';
import AppSiteOverview from '@/modules/site-overview/SiteOverview';
import AppLogin from '@/components/Login';

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: `/`,
        name: 'Sites List',
        component: AppSitesList,
        meta: {
            transition: 'list-back'
        }
    },
    {
        path: `/login`,
        name: 'Login',
        component: AppLogin,
    },
    {
        path: `/site/:guid/:language`,
        name: 'Site',
        component: AppSiteOverview,
        meta: {
            transition: 'list-forward'
        }
    },
    {
        path: `/site/:guid/:language/:pageGuid`,
        name: 'Site ',
        component: AppSiteOverview,
    },
    {
        path: `/:pathMatch(.*)*`,
        component: ''
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

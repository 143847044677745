<template>
  <div class="page-item-form" v-if="translationStruct.defaultFilterMeta">
    <!--      Заполнение мета-данных - дефолтная сортировка    -->
    <AppVariables :variables="page.variables" />
    <div class="page-item-form__form"
         v-if="translationStruct"
    >
      <div class="page-item-form__form-header">
        <h3>
          {{ page.defaultFilterMeta.about || "Default filter" }}
        </h3>
        <span v-if="page.defaultFilterMeta.about">
          Default filter
        </span>
      </div>
      <div class="page-item-form__form-fields">
        <AppInputHighlighted title="Title"
                             :half="true"
                             :text="translationStruct.defaultFilterMeta[getLanguage].title"
                             v-model="translationStruct.defaultFilterMeta[getLanguage].title"
        />
        <AppInputHighlighted title="Keywords"
                             :half="true"
                             :text="translationStruct.defaultFilterMeta[getLanguage].keywords"
                             v-model="translationStruct.defaultFilterMeta[getLanguage].keywords"
        />
        <AppInputHighlighted title="Description"
                             :rows="5"
                             :text="translationStruct.defaultFilterMeta[getLanguage].description"
                             v-model="translationStruct.defaultFilterMeta[getLanguage].description"
        />
      </div>
    </div>

    <!--      Заполнение мета-данных - кастомная сортировка    -->
    <div class="page-item-form__form"
         v-for="(filter, index) in page.customFiltersMeta"
         :key="filter.value + index"
    >
      <div class="page-item-form__form-header" v-if="filter.value && filter.param">
        <h3>
          {{ filter.about }}
        </h3>
        <span v-if="filter.value && filter.param">
            {{ `?${filter.param}=${filter.value}` }}
        </span>
      </div>
      <div class="page-item-form__form-fields" v-if="filter.value && filter.param">
        <AppInputHighlighted title="Title"
                             :half="true"
                             :text="translationStruct.customFiltersMeta[index][getLanguage].title"
                             v-model="translationStruct.customFiltersMeta[index][getLanguage].title"
        />
        <AppInputHighlighted title="Keywords"
                             :half="true"
                             :text="translationStruct.customFiltersMeta[index][getLanguage].keywords"
                             v-model="translationStruct.customFiltersMeta[index][getLanguage].keywords"
        />
        <AppInputHighlighted title="Description"
                             :rows="5"
                             :text="translationStruct.customFiltersMeta[index][getLanguage].description"
                             v-model="translationStruct.customFiltersMeta[index][getLanguage].description"
        />
      </div>
    </div>

    <div class="page-item-form__control">
      <div class="page-item-form__save"
           :class="{
            'page-item-form__save--loading': isLoadingLoc,
            'page-item-form__save--disabled': isLoadingLoc,
         }"
           @click="save"
      >
        <span v-if="!isLoadingLoc">Save</span>
        <span v-else class="page-item-form__loader"></span>
      </div>

      <transition name="fade">
        <div class="page-item-form__status page-item-form__status--success"
             v-if="showStatus && getRequestStatus === 'success'"
        >
          Success
        </div>
      </transition>

      <transition name="fade">
        <div class="page-item-form__status page-item-form__status--failure"
             v-if="showStatus && getRequestStatus === 'failure'"
        >
          Failure. Try again.
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AppVariables from '@/modules/page-item/components/Variables';
import AppInputHighlighted from '@/modules/page-item/components/InputHighlighted.vue';

import TranslationStruct from "@/modules/page-item/classes/translation-struct";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppPageItemForm",
  components: {
    AppVariables,
    AppInputHighlighted,
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  created() {
    this.translationStruct = new TranslationStruct(this.page);
  },
  data: () => ({
    isLoadingLoc: false,
    showStatus: false,
    translationStruct: null,
  }),
  computed: {
    ...mapGetters(['getLanguage', 'getLoadingGlob', "getRequestStatus"])
  },
  methods: {
    ...mapMutations(['setRequestStatus']),
    save() {
      if (this.isLoadingLoc || this.getLoadingGlob) return;

      this.$emit('save', this.translationStruct.getStruct(this.getLanguage));
      this.isLoadingLoc = true;

      let watcher;
      const sub_watch = () => {
        if (this.getLoadingGlob) {
          watcher = setTimeout(sub_watch, 200);
        } else {
          clearTimeout(watcher);
          this.isLoadingLoc = this.getLoadingGlob;

          this.showStatus = true;
          setTimeout(() => {
            this.showStatus = false;
            this.setRequestStatus('');
          }, 2500);
        }
      }

      sub_watch();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/transitions";

.page-item-form__form {
  margin-top: 20px;
  width: 100%;
}

.page-item-form__form-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > h3 {
    margin: 0;
  }

  & > span {
    margin-left: 20px;
    font-size: .8rem;
    opacity: .7;
  }
}

.page-item-form__form-fields {
  display: flex;
  gap: 10px 20px;
  width: 100%;
  flex-wrap: wrap;
}

.page-item-form__field {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & input {
    @include input;
    width: 100%;
    flex-grow: 1;
    margin-left: 0;
    margin-top: 5px;
  }

  & textarea {
    @include input;
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    font-size: 14px;
    height: unset;
    resize: none;
    font-family: sans-serif;
  }

  &--half {
    width: calc(50% - 10px);
  }
}

.page-item-form__control {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.page-item-form__save {
  @include button;
  width: max-content;
}

.page-item-form__loader {
  @include loader--primary;
}

.page-item-form__status {
  font-weight: bold;

  &--success {
    color: forestgreen;
  }

  &--failure {
    color: crimson;
  }
}

@include fade;
</style>

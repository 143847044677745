<template>
<div class="site-languages">
  <h2 class="site-languages__title">Select language</h2>
  <div class="site-languages__languages">
    <div v-for="language in languages"
         :key="language.name + Date.now()"
         class="site-languages__language"
         :title="`${language.translated ? 'Translated' : 'No translations'} ${language.name.toUpperCase()}`"
         :class="{
          'site-languages__language--translated': language.translated,
          'site-languages__language--current': isCurrentLanguage(language),
       }"
         @click="setLanguage(language)"
    >
      <svg>
        <use :xlink:href="`${require('@/assets/icons.svg')}#${language.name}`"></use>
      </svg>
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppSiteLanguages",
  props: {
    languages: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['getLanguage']),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    setLanguage(language) {
      const route = {
        name: this.$route.name,
        params: {
          ...this.$route.params,
          language: language.name,
        }
      }
      router.push(route);
      this.setLanguage(language.name);
    },
    isCurrentLanguage(language) {
      return this.getLanguage === language.name;
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.site-languages {
  margin-bottom: 20px;
}

.site-languages__title {
  margin: 0 0 10px 0;
}

.site-languages__languages {
  display: flex;
  align-items: center;
}

.site-languages__language {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(#000, .3);
  cursor: pointer;
  @include transition;

  &:hover:not(&--current, &--translated) {
    background-color: rgba(#000, .1);
    box-shadow: 0 0 5px 1px rgba($themeColor, .2);
  }

  & + & {
    margin-left: 10px;
  }

  &--translated {
    background-color: $themeColor;

    &:hover {
      background-color: rgba($themeColor, .7);
      box-shadow: 0 0 5px 1px rgba($themeColor, .2);
    }
  }

  &--current {
    background-color: #fff;
    outline: $themeColor solid 2px;
    box-shadow: 0 0 2px 2px rgba($themeColor, 1);
    pointer-events: none;
  }
}

.site-languages__language > svg {
  width: 23px;
  height: 15px;
}

</style>

<template>
<div class="filter-edit">
  <div class="filter-edit__filters">
    <!--    Flags      -->
    <div class="filter-edit__checkboxes">
      <!--    Пэджинейшн      -->
      <label class="filter-edit__checkbox"
             title="Switch pagination on current page"
      >
        <input type="checkbox"
               :checked="page.pagination"
               v-model="pageStruct.pagination"
               @change="saveStructure"
        >
        <span>
          Pagination
      </span>
      </label>

      <!--    Видимость      -->
      <label class="filter-edit__checkbox"
             title="Switch visibility of this page"
      >
        <input type="checkbox"
               :checked="page.hidden"
               v-model="pageStruct.hidden"
               @change="saveStructure"
        >
        <span>
          Hidden
      </span>
      </label>
    </div>

    <!--    Сортировка по умолчанию-->
    <AppEditableValue :value="page.defaultFilterMeta.about"
                      @confirm="setDefaultFilterName"
    >
      Default:
    </AppEditableValue>

    <!--    Добавление фильтров -->
    <div class="filter-edit__group">
      <h2 class="filter-edit__group-title">
        Add filters
      </h2>
      <div class="filter-edit__group-buttons">
        <div class="filter-edit__group-button"
             @click="addDefaultFilters('videos')"
        >
          Videos
        </div>
        <div class="filter-edit__group-button"
             @click="addDefaultFilters('models')"
        >
          Models
        </div>
        <div class="filter-edit__group-button"
             @click="addDefaultFilters('channels')"
        >
          Channels
        </div>
        <div class="filter-edit__group-button"
             @click="addDefaultFilters('playlists')"
        >
          Playlists
        </div>
        <div class="filter-edit__group-button filter-edit__group-button--inverted"
             @click="createNewForm('filter')"
        >
          + Add custom
        </div>
      </div>
    </div>
    <TransitionGroup tag="div" name="list" class="filter-edit__existing filter-edit__existing--filters">
      <AppFilterParamsLine v-for="(filter, index) in pageStruct.customFiltersMeta"
                           :key="filter.value + (filter.guid || '')"
                           :index="index"
                           @remove="removeFilter"
      >
        <AppEditableValue :value="filter.about"
                          @confirm="editFilter($event, index, 'about')"
        >
          Name:
        </AppEditableValue>
        <AppEditableValue :value="filter.param"
                          @confirm="editFilter($event, index, 'param')"
        >
          Parameter:
        </AppEditableValue>
        <AppEditableValue :value="filter.value"
                          @confirm="editFilter($event, index, 'value')"
        >
          Value:
        </AppEditableValue>
      </AppFilterParamsLine>
    </TransitionGroup>

    <!--    Добавление стандартных переменных -->
    <div class="filter-edit__group">
      <h2 class="filter-edit__group-title">
        Add variables
      </h2>
      <div class="filter-edit__group-buttons">
        <div class="filter-edit__group-button"
             v-for="(variable, index) in defaultVariables"
             :key="index + variable.value"
             @click="addDefaultVariable(variable)"
        >
          {{ variable.title }}
        </div>
        <div class="filter-edit__group-button filter-edit__group-button--inverted"
             @click="createNewForm('variable')"
        >
          + Add custom
        </div>
      </div>
    </div>
    <TransitionGroup tag="div" name="list" class="filter-edit__existing filter-edit__existing--vars">
      <AppFilterParamsLine v-for="(variable, index) in pageStruct.variables"
                           :key="variable.title + (variable.guid || '')"
                           :index="index"
                           @remove="removeVariable"
      >
        <AppEditableValue :value="variable.title"
                           @confirm="editVariable($event, index, 'title')"
        >
          Name:
        </AppEditableValue>
        <AppEditableValue :value="variable.value"
                          @confirm="editVariable($event, index, 'value')"
        >
          Variable:
        </AppEditableValue>
        <AppEditableValue :value="variable.method"
                          @confirm="editVariable($event, index, 'method')"
        >
          Method:
        </AppEditableValue>
      </AppFilterParamsLine>
    </TransitionGroup>
  </div>
</div>
</template>

<script>
import AppEditableValue from '@/components/EditableValue';
import AppFilterParamsLine from '@/modules/page-item/components/FilterParamsLine';

import PageStruct from "@/modules/page-item/classes/page-struct";
import DefaultFilters from "@/modules/page-item/templates/default-filters";
import DefaultVariables from "@/modules/page-item/templates/default-variables";
import defaultVariables from "@/modules/page-item/templates/default-variables";

export default {
  name: "AppPageItemSorts",
  components: {
    AppEditableValue,
    AppFilterParamsLine,
  },
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  beforeMount() {
    this.pageStruct = new PageStruct();
    this.defaultVariables = DefaultVariables();

    if (this.page) {
      this.pageStruct.setStruct(this.page);
    }
  },
  data: () => ({
    isExpanded: false,
    pageStruct: {},
  }),
  methods: {
    defaultVariables() {
      return defaultVariables
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    saveStructure() {
      this.$emit('save-struct', this.pageStruct.getStruct());
    },
    setDefaultFilterName(name) {
      if (!this.pageStruct) return;
      this.pageStruct.setDefaultFilter({ ...this.pageStruct.defaultFilterMeta, about: name });
      this.saveStructure();
    },
    editFilter(newValue, index, prop) {
      if (!this.pageStruct) return;

      this.pageStruct.customFiltersMeta[index][prop] = newValue;
      this.saveStructure();
    },
    removeFilter(index) {
      this.pageStruct.removeFilter(index);
      this.saveStructure();
    },
    addDefaultFilters(type) {
      DefaultFilters[type]?.forEach(filter => {
        this.pageStruct.addFilter(...Object.values(filter));
      });
      this.saveStructure();
    },
    createNewForm(name) {
      switch (name) {
        case 'filter' :
          this.pageStruct.addFilter();
          break;
        case 'variable' :
          this.pageStruct.addVariable();
          break;
        default :
          return;
      }
    },
    editVariable(newValue, index, prop) {
      if (!this.pageStruct) return;

      this.pageStruct.variables[index][prop] = newValue;
      this.saveStructure();
    },
    removeVariable(index) {
      this.pageStruct.removeVariable(index);
      this.saveStructure();
    },
    addDefaultVariable(variable) {
      this.pageStruct.addVariable(...Object.values(variable));
      this.saveStructure();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/transitions";

.filter-edit__button {
  @include button;
  width: 300px;

  & + * {
    margin-top: 20px;
  }
}

.filter-edit__checkboxes {
  display: flex;
  gap: 50px;
  height: 30px;
  margin-bottom: 10px;
}

.filter-edit__checkbox {
  @include checkbox;
}

.filter-edit__group {
  margin-bottom: 10px;
}

.filter-edit__group-title {
  margin: 0 0 10px 0;
  font-size: 16px;
}

.filter-edit__group-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-edit__group-button {
  @include button;
  margin: 0 !important;
}

.filter-edit__existing {
  margin-bottom: 20px;
}

@include list;
</style>

const PageTranslations  = {
    ar: 'صفحة',
    ch: '页',
    en: 'page',
    es: 'página',
    de: 'seite',
    fr: 'page',
    in: 'पृष्ठ',
    it: 'pagina',
    jp: 'ページ',
    ko: '페이지',
    ru: 'страница',
}

export default PageTranslations;

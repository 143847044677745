<template>
  <div class="loader--wrapper">
    <div class="loader">
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader"
}
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';

.loader--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  margin: 20px 0;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  border-right-color: $themeColor;
  border-radius: 50%;

  animation: 1s linear infinite rotate;

  @keyframes rotate {
    0% {
      rotate: 0deg;
    }

    100% {
      rotate: 360deg;
    }
  }
}
</style>

<template>
<div class="sites-list">
  <!--        Controls      -->
  <section class="sites-list__controls"
           v-if="sites.length"
  >
    <AppQuickSearch @search="onSearch"
                    placeholder="Site title..."
    />
    <AppSitesFilter @filter="onFilter"
                    :translated-sites-count="translatedSitesCount"
    />
  </section>

  <!--        List      -->
  <TransitionGroup name="list" tag="section" class="sites-list__list"
           v-if="sites.length"
  >
    <AppSiteItem v-for="site in filteredSites"
                 :key="site.GUID"
                 :site="site"
    />
  </TransitionGroup>

  <!--        Controls      -->
  <app-loader v-if="isLoading"></app-loader>

  <!--        Error      -->
  <section class="sites-list__error"
           v-if="error"
  >
    Error: {{ error }}
  </section>
</div>
</template>

<script>
import AppSiteItem from '@/modules/sites-list/components/SiteItem';
import AppQuickSearch from '@/components/QuickSearch.vue';
import AppSitesFilter from '@/modules/sites-list/components/SitesFilter';
import AppLoader from '@/components/Loader.vue';

import { mapMutations, mapGetters } from "vuex";

import UserService from "@/services/user-service";
import SitesService from "@/services/sites-service";

export default {
  name: "AppSitesList",
  components: {
    AppSiteItem,
    AppQuickSearch,
    AppSitesFilter,
    AppLoader,
  },
  data: () => ({
    error: null,
    filter: false,
    isLoading: false,
    search: '',
    sites: [],
  }),
  computed: {
    ...mapGetters(['getAuthStatus', 'getCurrentTitle']),
    filteredSites() {
      return this.sites
          .filter(site => {
            return !this.filter || Object.keys(site.languages).every(key => site.languages[key].translated || false);
          })
          .filter(site => {
            return !this.search || site.title.toLowerCase().includes(this.search);
          });
    },
    translatedSitesCount() {
      return 0;
      // return this.sites.filter(site => site.languages.every(language => language.translated)).length;
    }
  },
  beforeMount() {
    this.getList();
  },
  mounted() {
    this.setTitle();
  },
  methods: {
    ...mapMutations(['setCurrentTitle']),
    async getList() {
      if (!this.getAuthStatus) return;
      this.isLoading = true;

      const getAll = await SitesService.getAll()
          .finally(() => {
            this.isLoading = false;
          })

      if (getAll.success) {
        this.sites = getAll.data;
        return;
      }

      this.error = getAll.error;
    },
    onFilter(status) {
      this.filter = status;
    },
    onSearch(search) {
      this.search = search;
    },
    setTitle() {
      try {
        this.setCurrentTitle(`Welcome, ${ UserService.getUserInfo().login }!`);
        document.title = this.getCurrentTitle;
      } catch (err) {
        setTimeout(this.setTitle, 150);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/mixins';
@import '../../styles/transitions';

.sites-list {
  @include content-container;
}

.sites-list__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@include list;
</style>

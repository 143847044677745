<template>
<div class="page-item-status" v-if="status" title="Translated"/>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppPageItemStatus",
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['getLanguage']),
    status() {
      let hasTranslatedDefault = false;
      let hasTranslatedCustom = false;

      if (this.page.defaultFilterMeta.meta) {
        hasTranslatedDefault = !!this.page.defaultFilterMeta.meta[this.getLanguage]
            && this.page.defaultFilterMeta.meta[this.getLanguage].translated
            || false;
      }

      if (this.page.customFiltersMeta) {
        hasTranslatedCustom = this.page.customFiltersMeta.every(lang => {
          return lang.meta[this.getLanguage].translated || false;
        });
      }

      return hasTranslatedDefault && hasTranslatedCustom;
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../styles/colors";

.page-item-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: forestgreen;
  position: absolute;
  right: 0;

  &::after {
    content: "";
    display: block;
    width: 7px;
    height: 13px;
    border-bottom: 2px solid $themeTextColor;
    border-right: 2px solid $themeTextColor;
    transform: rotate(45deg) translate(-1px, -2px);
    //position: absolute;
    //left: 50%;
    //top: 50%;
  }
}
</style>

<template>
<div class="filter-params-line">
  <div class="filter-params-line__list">
    <slot/>
  </div>
  <div class="filter-params-line__button filter-params-line__button--red filter-params-line__button--icon"
       @click="remove"
  >
    <svg v-if="!isLoadingLoc">
      <use xlink:href="@/assets/icons.svg#remove"></use>
    </svg>

    <span class="filter-params-line__loader"
          v-else
    ></span>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppFilterParamsLine",
  props: {
    index: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['getLoadingGlob'])
  },
  data: () => ({
    isLoadingLoc: false,
  }),
  methods: {
    remove() {
      if (this.isLoadingLoc || this.getLoadingGlob) return;

      this.$emit('remove', this.index);
      this.isLoadingLoc = true;

      let watcher;
      const sub_watch = () => {
        if (this.getLoadingGlob) {
          watcher = setTimeout(sub_watch, 200);
        } else {
          clearTimeout(watcher);
          this.isLoadingLoc = this.getLoadingGlob;
        }
      }

      sub_watch();
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/colors";
@import "../../../styles/mixins";

.filter-params-line {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 8px;

  & + & {
    margin-top: 5px;
  }
}

.filter-params-line__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 20px;

  & > * {
    width: min-content;
    min-width: 300px;
  }
}

.filter-params-line__button {
  @include button;
  margin-left: auto;
}

.filter-edit__existing .editable-value {
  margin: 0 !important;
}

.filter-params-line__loader {
  @include loader--primary;
}
</style>

import { createStore } from "vuex";

const store = createStore({
    state: {
        currentTitle: '',
        currentSite: '',
        currentPage: '',
        currentUser: null,
        isAuth: false,
        language: 'en',
        loadingGlob: false,
        requestStatus: '',
    },
    mutations: {
        setAuth: (state, value) => state.isAuth = value,
        setCurrentTitle: (state, title) => state.currentTitle = title,
        setCurrentSite: (state, title) => state.currentSite = title,
        setCurrentPage: (state, title) => state.currentPage = title,
        setCurrentUser: (state, user) => state.currentUser = user,
        setLanguage: (state, language) => state.language = language,
        setLoadingGlob: (state, value) => state.loadingGlob = value,
        setRequestStatus: (state, value) => state.requestStatus = value,
        modifyCurrentTitle:  (state, postfix) => state.currentTitle = `${state.currentTitle} · ${postfix}`,
    },
    getters: {
        getAuthStatus: state => state.isAuth,
        getCurrentTitle: state => state.currentTitle,
        getLanguage: state => state.language,
        getLoadingGlob: state => state.loadingGlob,
        getRequestStatus: state => state.requestStatus,
        getUserInfo: state => state.currentUser,
        isAdmin: state => state.currentUser.roles.find(item => item === 'administrators') || false,
    },
    actions: {

    },
    modules: {

    },
});

export default store;

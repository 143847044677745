export default class Filter {
    constructor(about = '', param = '', value = '') {
        this.about = about;
        this.param = param;
        this.value = value;
    }

    get() {
        return {
            about: this.about,
            param: this.param,
            value: this.value,
        };
    }
}

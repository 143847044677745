import Config from "../../public/config";

export default class APIService {
    static get(path) {
        return async function () {
            return fetch(
                `${Config.apiEndpoint}${path}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token') || ''}`
                    }
                }
            )
            .then(res => res.json())
            .then(data => data)
            .catch(err => err);
        }
    }

    static post(path) {
        return async function (data) {
            return fetch(
                `${Config.apiEndpoint}${path}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token') || ''}`
                    },
                    body: JSON.stringify(data),
            })
            .then(res => res.json())
            .then(data => data)
            .catch(err => err);
        }
    }
}
